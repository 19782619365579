import { useStaticQuery, graphql } from "gatsby"
export const useWoningenQuery = () => {

  const data = useStaticQuery(
  graphql`
  query WoningenQuery {
    wpPage(slug: {eq: "aanbod"}) {
        uri
      }
      allWpVerkopendeMakelaar {
        nodes {
          title
          verkopendeMakelaars {
            fieldGroupName
            foto {
              mediaItemUrl
             
            }
            telefoonnummer
            vestiging
            volgnummer
          }
        }
      }
    allWpWoning(sort: {order: DESC, fields: hqInvoerDatum}) {
        nodes {
          id
          title
          uri
          hqAdres
          hqBouwvorm
          hqKoopconditie
          hqKoopprijs
          hqHuurprijs
          hqHuurconditie
          hqDatumAanvaarding
          hqStatus
          hqWoonoppervlak
          hqPerceel
          hqAdresNr
          hqAdresNrToevoeging
          hqAdresPlaats
          hqAdresPostcode
          hqAdresStraat
          hqKamers
          hqVestiging
          hqSlaapkamers
          hqObject
          hqObjecttype
          hqContactpersoon
          hqOpenHuis
          hqOpenHuisStart
          hqOpenHuisEind
          featuredImage {
            node {
              mediaItemUrl
              
            }
          }
        }
    }
  }
  `
)

  return data;
}

