import { useStaticQuery, graphql } from "gatsby"
export const useSettingsQuery = () => {

    const data = useStaticQuery(
        graphql`
        query ACFQuery {
              allWp {
                edges {
                  node {
                    acfOptionsHomepagina {
                      homepageField {
                        homepaginaAfbeeldingAlgemeen {
                          mediaItemUrl
                        }
                        heroTitel
                        homepaginaKnoppen {
                          knopLink
                          knopTitel
                        }
                        homepaginaKnoppenTitel
                        homepaginaRecentAanbodTitel
                      }
                    }
                  }
                }
              }
        }
        `
      )

  return data;
}

