import { faArrowsAltH, faBed } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import { getImage, StaticImage, GatsbyImage } from "gatsby-plugin-image";
import React, {useState} from "react";
import './Card.css'
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import CardSeller from "./CardSeller/CardSeller";

const Card = (props) => {
    const styles = props.styles
    const data = props.data
    const seller = props.seller
    const priceInt = data.hqKoopprijs !== '' ? parseInt(data.hqKoopprijs) : data.hqHuurprijs !== '' ? parseInt(data.hqHuurprijs) : 0
    const priceCurr = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(priceInt).replace(',00', ',-');
    const openHuis = data.hqOpenHuis
    const dateStart = data.hqOpenHuisStart ? new Date(data.hqOpenHuisStart.replace(/-/g, '/')).getTime() : null
    const dateNow = new Date().getTime();

    let conditie = '';
    // console.log(data.hqContactpersoon, data.title)
    if(data.hqKoopconditie !== ''){
        data.hqKoopconditie === 'Kosten_koper' ? 
        conditie = 'k.k.'
        :
        conditie = 'v.o.n.'
    }else if(data.hqHuurconditie !== ''){
        data.hqHuurconditie === 'Per_maand' ? 
        conditie = '/ maand'
        :
        conditie = ''
    }
    const price = `${priceCurr} ${conditie}`
   
    const length = props.length

    let img;

    const key = props.delay
    const animDelay = key !== undefined ? key * 0.15 : 0
    const woonoppervlak = <span>{data.hqWoonoppervlak !== "" ? data.hqWoonoppervlak : data.hqPerceel}m²</span>

    if(data.featuredImage !== null){
    data.featuredImage.node ?
    img = data.featuredImage.node?.mediaItemUrl
    :
    img = ''
    }
    const [cardState, setCardState] = useState(null)

    let hidden = 'hidden'

    if(data.hqStatus === 'Beschikbaar' && data.hqOpenHuis !== 'True'){
        hidden = 'hidden'
    }else if(data.hqStatus !== 'Beschikbaar' || data.hqOpenHuis == 'True' && dateStart > dateNow ){
        hidden = 'block'
    }else if(data.hqStatus === undefined){
        hidden = 'hidden'
    }

    return(
        <>
            <div className="h-full">
            <Link to={data.uri}>
                <motion.div layoutId={key} initial={{translateY: 75, opacity: 0}} animate={{translateY: 0, opacity: 1}} transition={{duration: 0.2, delay: animDelay }} onClick={() => setCardState(key)} className={` card ${length > 3 ? ``: `max-h-8/12`} h-full p-0 transition-all duration-300 ease-in ${styles} col-span-1 row-span-1 relative `} >
                    <span className={`bg-red opacity-100 text-white text-sm absolute px-5 py-2 top-0 left-0 z-20 ${hidden}`}>{data.hqStatus === 'Beschikbaar' ? openHuis == 'True' && dateStart > dateNow  ? 'Open huis' : null : data.hqStatus.replaceAll('_', ' ')}</span>
                        <div className="object-cover w-full h-3/6 m-0 image_container ">
                            <div className="flex row ">
                                <div className="max-h-full w-full">
                                    {data.featuredImage !== null ?
                                    <img src={img} alt={`Woning aan de ${data.title}`} className={`h-48 max-h-2/6 object-cover w-full mb-0 z-10`}></img>
                                    : 
                                    null
                                    }
                                </div>
                            </div>
                            <div className="flex flex-row p-5 bg-light justify-between w-full">
                                <div className="flex flex-col h-full w-full">
                                    <h3 className="text-blue text-lg m-0">{data.hqAdres}</h3>
                                    <span className="text-blue opacity-40 text-sm "><span className="uppercase">{data.hqAdresPostcode}</span> {data.hqAdresPlaats}</span>
                                    <span className="text-blue text-sm my-3">{woonoppervlak} {data.hqKamers !== "" ? <span className="mx-1">&bull;</span> : null} <span>{data.hqKamers} {data.hqKamers !== "" ? 'kamers' : ''}</span></span>
                                    <h3 className="text-blue text-sm lg:text-xl mx-0 mb-3">{price}</h3>
                                    
                                    <CardSeller data={data} seller={seller} huur={data.hqHuurconditie !== ''}></CardSeller>
                                </div>
                            </div>
                        </div>
                </motion.div>
            </Link>
            </div>
        </>
    )
}

export default Card;