import React, { useContext } from "react";
import Container from "../Container/Container";
import './contentImageLeft.css';
import placeholder from "../../images/woning.jpg"
import VestigingContext from "../../store/vestiging-context";
import { useVestigingenQuery } from "../../hooks/useVestigingenQuery"
import beilen from "../../images/teamfoto_groenhout_beilen.jpg"
import assen from "../../images/te-koop.jpeg"
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import { Link } from "gatsby";

const ContentImageLeft = (props) => {

    const ctx = useContext(VestigingContext)
    const styles = props.styles
    const bg = props.bg
    const vestiging = ctx.vestiging
    const landingVestiging = props.vestiging
    const landingImage = props.image
    const data = useVestigingenQuery()
    const alt = props.imgAlt
    const usp = props.usp
    
    const video = props.vid

    const image = vestiging !== undefined && vestiging !== 'undefined' ? data.allWpVestiging.nodes.filter((e) => e.title === vestiging) : null
    let img;

    if(vestiging === 'Beilen'){
        img = beilen
    } else if(vestiging === 'Assen'){
        img = assen
    }else{
        img = undefined
    }


    console.log()
    
    return(
       <div className={`${bg ? `content__container pt-16 lg:pt-32` : ``} ${styles}`}> 
            <Container styles={`sm:px-8`}>
                <div className={`flex flex-col  ${props.reverse ? `lg:flex-row-reverse` : `lg:flex-row`}`}>
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{duration: 0.3, delay: 0 }} className="w-full h-3/6 lg:h-auto lg:w-3/6">
                    {landingImage !== undefined && video === undefined ?
                    <img src={landingImage} alt={alt} className={`${props.contain ? `object-contain` : `object-cover`} content__img__left--img w-full lg:w-10/12 mr-auto h-full m-0`}></img>
                    : video !== undefined ?
                    <iframe id="videoBG" src={video} frameborder="0" allow="autoplay;" className="w-full h-72 m-0"></iframe>
                    :
                    null
                    }
                    </motion.div>
                    <div className="w-full lg:w-3/6 lg:mt-0 flex flex-col justify-center">
                        {props.title !== undefined ?
                            <motion.h2 initial={{ opacity: 0 }} animate={{  opacity: 1 }} transition={{ duration: .3, delay: 0 }} className="text-blue text-3xl w-10/12 lg:w-full mx-auto mt-8 lg:m-0 font-black">{props.title}</motion.h2>
                        :   
                            <motion.h2 initial={{ opacity: 0 }} animate={{  opacity: 1 }} transition={{ duration: .3, delay: 0 }} className="text-blue text-3xl w-10/12 lg:w-8/12 mx-auto mt-8 lg:m-0 font-black">Dé makelaar in {vestiging !== undefined && landingVestiging === undefined && vestiging !== 'undefined' ? vestiging : landingVestiging !== undefined ? landingVestiging : `Assen, Beilen en Hoogeveen`}</motion.h2>
                        }
                        <div>
                            <motion.p initial={{ opacity: 0 }} animate={{  opacity: 1 }} transition={{ duration: .3, delay: 0 }} className="text-gray mx-auto lg:mx-0 w-10/12 lg:w-full lg:mt-2" dangerouslySetInnerHTML={{__html: props.sub}}></motion.p>
                            <ul className={`mx-auto lg:m-0 text-gray w-10/12 lg:w-full list-none ${usp !== null ? `pb-5` : `` }`}>
                                {
                                    usp !== null && usp !== undefined ? usp.map((e, i)=>(
                                        <motion.li initial={{ translateY: -100, opacity: 0 }} animate={{ translateY: 0, opacity: 1 }} transition={{ duration: .3, delay: i * .15 + .15 }}><span className="mr-3 text-blue list-none">{props.icon}</span>{e}</motion.li>
                                    )) : null
                                }
                            </ul>
                        </div>
                        {props.link !== undefined ?
                        <div>
                                <Link to={props.link} className="text-white px-5 py-4 bg-red transition-colors duration-300 fullBtn "><span className="buttonHoverWhite py-1">Lees meer</span></Link>
                        </div>
                        :
                        null
                        }
                        {props.cta !== undefined ?
                            <span>{props.cta}</span>
                        :
                            null
                        }
                    </div>
                </div>
            </Container>
       </div> 
    )
}

export default ContentImageLeft;