// import { GatsbyImage } from "gatsby-plugin-image/dist/src/components/gatsby-image.browser";
import { getImage, StaticImage, GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const CardSeller = (props) => {
    const data = props.data
    const sellerData = props.seller
    let seller = null
    let huur = props.huur
    
    seller = sellerData.nodes.filter((sell) => {
        // console.log(data.hqContactpersoon, sell.verkopendeMakelaars.volgnummer)
        if(sell.verkopendeMakelaars.volgnummer === data.hqContactpersoon){
            return true;
        } else{ return false }
    })

    // console.log(data.hqContactpersoon, sell.verkopendeMakelaars.volgnummer)

    const sellerName = seller.length > 0 ? seller[0].title : ''
    const sellerVestiging = seller.length > 0 ? `` : `Groenhout ${data.hqVestiging}`
    const phoneNumber = seller.length > 0 ? seller[0].verkopendeMakelaars.telefoonnummer : ''
    const image = seller.length > 0 ? seller[0].verkopendeMakelaars.foto.mediaItemUrl : ''
    const phoneDisplay = phoneNumber !== '' ?
        phoneNumber.startsWith("06") ?  
            phoneNumber.substr(0,2) + ' - ' + phoneNumber.substr(2) 
        :
            phoneNumber.substr(0,4) + ' - ' + phoneNumber.substr(4) 
        :
        '' 
    
    return(
        <div className="flex flex-row items-end lg:items-center justify-between">
            <div className="">
                <p className="text-blue opacity-40 text-sm m-0 relative z-40">In de {`${huur ? `verhuur` : `verkoop`}`} bij </p>
                <p className="text-blue text-sm m-0 relative z-40">{`${sellerName} ${sellerVestiging}`}</p>
                <a href={`https://api.whatsapp.com/send/?phone=31${phoneNumber.replace(/\s/g, '').replace('-', '')}`} ><span className="text-red text-sm mt-1">{phoneDisplay}</span></a>
            </div>
            {image !== '' ?
            <img src={image} alt={`De verkopende makelaar van de woning ${data.title}`} className="rounded-full m-0 ml-3 lg:ml-0 object-cover lg:w-16 w-16 lg:h-16 h-16  hidden md:block" ></img>
            : null}
        </div>
    )
} 

export default CardSeller;